import TYPES from '@/types';
import { v4 as uuid } from 'uuid';
import i18n from '@/vue-app/plugins/i18n';
import Vue from 'vue';

import {
  requiredRule,
  emailFormat,
  phoneFormat,
  requiredRuleByKey, RuleResponseType,
} from '@/vue-app/utils/form-rules';

// Application
import { SearchRelationshipsQuery }
  from '@/modules/my-investment/catalogs/allianz/relationship/application/queries/';
import GetPersonQueryService
  from '@/modules/my-investment/person/application/queries/get-my-investment-person-query';
import { SearchOnBoardingStepsQuery }
  from '@/modules/my-investment/on-boarding-steps/application/queries';
import { SearchInvestmentProvidersQuery }
  from '@/modules/my-investment/investment-provider/application/queries';
import {
  CreateOnBoardingStepCommand,
  UpdateOnBoardingStepCommand,
} from '@/modules/my-investment/on-boarding-steps/application/commands';

// Domain
import {
  OnBoardingStepEntity,
} from '@/modules/my-investment/on-boarding-steps/domain/entities/on-boarding-step-entity';
import {
  InvestmentProviderEntity,
} from '@/modules/my-investment/investment-provider/domain/entities/investment-provider-entity';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';
import Translator from '@/modules/shared/domain/i18n/translator';
import { Values } from '@/modules/shared/domain/i18n/types';
import Inject from '@/modules/shared/domain/di/inject';

export default class ContractSavingsBeneficiariesViewModel {
  @Inject(TYPES.SEARCH_CATALOG_ALLIANZ_RELATIONSHIP_QUERY)
  private readonly searchRelationshipsQuery!: SearchRelationshipsQuery;

  @Inject(TYPES.GET_INVESTMENT_PERSON_QUERY)
  private readonly get_person_query!: GetPersonQueryService;

  @Inject(TYPES.SEARCH_MY_INVESTMENT_ON_BOARDING_STEP_REPOSITORY)
  private readonly search_on_boarding_steps_query!: SearchOnBoardingStepsQuery;

  @Inject(TYPES.SEARCH_INVESTMENT_PROVIDER_QUERY)
  private readonly search_investment_providers_query!: SearchInvestmentProvidersQuery;

  @Inject(TYPES.CREATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly create_on_boarding_step_command!: CreateOnBoardingStepCommand;

  @Inject(TYPES.UPDATE_MY_INVESTMENT_ON_BOARDING_STEP_COMMAND)
  private readonly update_on_boarding_step_command!: UpdateOnBoardingStepCommand;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  @Inject(TYPES.I18N)
  private readonly translator!: Translator;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.contract-savings.beneficiaries';

  readonly error_message = i18n.tc('components.contract-savings.error_on_save_step');

  investment_provider_name = 'allianz';

  exists_step = false;

  step_name = 'beneficiaries';

  investment_provider_id = '';

  is_loading = true;

  small_screen = false;

  country_codes = ['+52', '+1'];

  beneficiaries = [
    {
      name: '',
      last_name: '',
      second_last_name: '',
      email: '',
      phone: '',
      phone_country_code: this.country_codes[0],
      percentage: '0',
      relationship: {
        cve_parentesco: '',
        desc_parentesco: '',
      },
    },
  ];

  on_boarding_step: OnBoardingStepEntity = {
    id: uuid(),
    current_step: '',
    investment_provider_id: '',
    payload: {},
  };

  validateOneHundredPercent = (): RuleResponseType => (
    this.validatePercentage()
    || this.translator.translate(`${this.i18n_namespace}.percent.error`)
  );

  rules = {
    name: [requiredRule],
    last_name: [requiredRule],
    second_last_name: [requiredRule],
    phone: [
      requiredRule,
      (value: string) => phoneFormat('+52', value),
    ],
    phone_country_code: [requiredRule],
    email: [emailFormat],
    relationship: [(value: string) => requiredRuleByKey(value, 'cve_parentesco')],
    percentage: [this.validateOneHundredPercent],
  };

  forms = [false];

  invalid_percentage = false;

  panel: Array<number> = [];

  public constructor(view: Vue) {
    this.view = view;
  }

  get validForms(): boolean {
    return !this.forms.some((form) => !form);
  }

  get user_name() {
    return this.get_person_query.execute().name;
  }

  get investmentProviders() {
    return this.search_investment_providers_query.execute();
  }

  get steps() {
    return this.search_on_boarding_steps_query.execute(this.investment_provider_id);
  }

  get relationships() {
    return this.searchRelationshipsQuery.execute();
  }

  get continue_button_disabled() {
    return !this.validForms || this.invalid_percentage || this.is_loading;
  }

  get disable_add_more_beneficiaries() {
    return this.beneficiaries.length > 4 || this.is_loading;
  }

  translate = (message: string, values?: Values) => this.translator.translate(`${this.i18n_namespace}.${message}`, values);

  validatePercentage = () => {
    const sum = this.beneficiaries.reduce(
      (total, beneficiary) => total + parseInt(beneficiary.percentage, 10), 0,
    );
    this.invalid_percentage = sum !== 100;
    return !this.invalid_percentage;
  }

  validateForms = () => {
    this.beneficiaries.forEach((beneficiary, index) => {
      if (this.view.$refs[`form_${index}`]) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
        // @ts-ignore
        this.view.$refs[`form_${index}`][0].validate();
      }
    });
  }

  setInvestmentProviderId = (investment_providers: Array<InvestmentProviderEntity>) => {
    if (investment_providers.length) {
      const search_provider = investment_providers.find(
        (item) => item.name === this.investment_provider_name,
      );
      if (search_provider) {
        this.investment_provider_id = search_provider.id;
      }
    }
  };

  verifyStep = async (on_boarding_steps: Array<OnBoardingStepEntity>) => {
    const search_step = on_boarding_steps.find(
      (step) => step.current_step === this.step_name,
    );
    if (search_step) {
      this.exists_step = true;
      this.on_boarding_step = search_step;
      this.setInputsDataFromStep();
      this.forms = this.forms.map((form) => !form);
    }
  };

  setInputsDataFromStep = () => {
    const { beneficiaries } = this.on_boarding_step.payload;
    this.beneficiaries = beneficiaries;
  };

  removeBeneficiary(beneficiary: number) {
    this.beneficiaries.splice(beneficiary, 1);
    this.forms.splice(beneficiary, 1);
    this.validatePercentage();
    this.validateForms();
  }

  addBeneficiary = () => {
    if (this.beneficiaries.length < 5) {
      this.forms.push(false);
      this.beneficiaries.push({
        name: '',
        last_name: '',
        second_last_name: '',
        relationship: {
          cve_parentesco: '',
          desc_parentesco: '',
        },
        percentage: '0',
        phone: '',
        phone_country_code: this.country_codes[0],
        email: '',
      });
      this.panel.push(this.beneficiaries.length - 1);
    }
  };

  async saveStep() {
    this.is_loading = true;
    try {
      this.on_boarding_step.current_step = this.step_name;
      this.on_boarding_step.investment_provider_id = this.investment_provider_id;
      this.on_boarding_step.payload.beneficiaries = this.beneficiaries;
      if (this.exists_step) {
        delete this.on_boarding_step.customer_id;
        await this.update_on_boarding_step_command.execute(this.on_boarding_step);
      } else {
        await this.create_on_boarding_step_command.execute(this.on_boarding_step);
      }
    } catch (error) {
      this.message_notifier.showErrorNotification(this.error_message);
      this.is_loading = false;
      return false;
    }
    this.is_loading = false;
    return true;
  }

  disableTrash = (index: number) => index === 0;

  prevStep = () => {
    this.view.$emit('prevStep');
  }

  nextStep = async () => {
    const save_step = await this.saveStep();
    if (save_step) {
      this.view.$emit('nextStep');
    }
  }

  initialize = async (screen_size: string) => {
    this.small_screen = screen_size === 'small';
    const investment_providers = await this.investmentProviders;
    this.setInvestmentProviderId(investment_providers);
    const steps = await this.steps;
    await this.verifyStep(steps);
    this.is_loading = false;
  }
}
